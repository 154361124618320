<template>
    <headerComponent />
    <Toolbar>
        <template v-slot:right>
            <Button label="Add DID Number" icon="pi pi-plus" class=" p-mr-2" @click="addDIDNumberDialogOpen()" />
            <Button v-if="!showFillFilter" label="Filter" icon="pi pi-filter" @click="filterlisting()"></Button>
            <Button v-if="showFillFilter" label="Filter" icon="pi pi-filter-slash" class="p-button-success"
                @click="filterlisting()"></Button>
        </template>
    </Toolbar>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="didnumberlist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bd1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="DID Number" headerStyle="width: 19%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn2 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="City" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn22 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Telecom Operator" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn4 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Service Provider" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.cn6 }}</div>
                            </div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 8%" class="p-text-center">
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded "
                                @click="editDIDNumberDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add DID number dialog start here -->
    <Dialog v-model:visible="addDIDNumberDialogStatus" :style="{ width: '900px' }" header="Add DID Number" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-5">
                    <label for="didnumber">
                        DID Number
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="didnumber" class="p-text-capitalize" v-model.trim="add.didnumber" required="true"
                        maxlength="15" autofocus :class="{ 'p-invalid': submitted && !add.didnumber }" />
                    <small class="p-invalid p-error" v-if="v$.add.didnumber.$error">{{
                v$.add.didnumber.$errors[0].$message
            }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="operator">
                        Operator
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="operator" v-model="add.operator" :options="operatorList" optionLabel="label"
                        placeholder="Select Operator" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.operator.$error">{{ v$.add.operator.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="state">
                        State
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="state" v-model="add.state" :options="statesList" optionLabel="label"
                        placeholder="Select State" :filter="true" @change="selectclientcity($event)" appendTo="body">
                    </Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.state.$error">{{ v$.add.state.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label for="city">
                        City
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="city" v-model="add.city" :options="citiesList" optionLabel="label"
                        placeholder="Select City" :filter="true" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.city.$error">{{ v$.add.city.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="add.numbertype"
                            :checked="add.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="add.numbertype"
                            :checked="add.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
                <div class="p-field p-col-12 p-md-5" v-if="add.numbertype == 2">
                    <label>Defualt Number</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="defualt_number_yes" name="option" v-bind:value="1"
                            v-model="add.defaultnumberstatus" :checked="add.defaultnumberstatus == 1" />
                        <label for="defualt_number_yes" class="p-mr-2">Yes</label>
                        <RadioButton id="defualt_number_no" name="option" v-bind:value="0"
                            v-model="add.defaultnumberstatus" :checked="add.defaultnumberstatus == 0" />
                        <label for="defualt_number_no" class="p-mr-2">No</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addNewDIDNumber()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add DID number dialog end here -->

    <!-- edit DID Number start here -->
    <Dialog v-model:visible="updateDIDNumberDialogStatus" :style="{ width: '900px' }" header="Update DID Number"
        :modal="true" class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-11">
                    <label for="status">
                        Status
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="status" v-model="edit.status" :options="statusList" optionLabel="label"
                        placeholder="Select Status" appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.edit.status.$error">{{ v$.edit.status.required.$message
                        }}</small>
                </div>
                <div class="p-field p-col-12 p-md-5">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="edit.numbertype"
                            :checked="edit.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="edit.numbertype"
                            :checked="edit.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updateDIDNumber()" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit DID Number dialog end here -->

    <!----Start of filter--------------------------------->
    <Dialog v-model:visible="filterListingDialog" :style="{ width: '600px' }" header="Filter" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                    <label for="client">Client</label>
                    <MultiSelect v-model="client_fks" :options="clientList" optionValue="value" optionLabel="label"
                        placeholder="Select Client" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="operator">
                        Operator
                    </label>
                    <!-- <Dropdown id="operator" v-model="operator"
                        :options="operatorList" optionLabel="label" placeholder="Select Operator" :filter="true"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="operator_fks" :options="operatorList" optionValue="value" optionLabel="label"
                        placeholder="Select Operator" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="didnumber">
                        DID Number
                    </label>
                    <InputText id="didnumber" class="p-text-capitalize" v-model.trim="add.didnumber" required="true"
                        maxlength="50" />
                </div>
                <div class="p-field p-col-12">
                    <label for="state">
                        State
                    </label>
                    <!-- <Dropdown id="state" v-model="state" 
                        :options="statesList" optionLabel="label" placeholder="Select State" :filter="true" @change="selectclientcity($event)"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="state_fks" :options="statesList" optionValue="value" optionLabel="label"
                        placeholder="Select State" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label for="city">
                        City
                    </label>
                    <!-- <Dropdown id="city" v-model="city" 
                        :options="citiesList" optionLabel="label" placeholder="Select City" :filter="true"
                        appendTo="body"></Dropdown> -->
                    <MultiSelect v-model="city_fks" :options="citiesList" optionValue="value" optionLabel="label"
                        placeholder="Select City" :filter="true" class="multiselect-custom" appendTo="body">
                    </MultiSelect>
                </div>
                <div class="p-field p-col-12">
                    <label>Number Type</label>
                    <div class="p-field-radiobutton">
                        <RadioButton id="normal_number" name="option" v-bind:value="1" v-model="add.numbertype"
                            :checked="add.numbertype == 1" />
                        <label for="normal_number" class="p-mr-2">Normal Number</label>
                        <RadioButton id="callback_number" name="option" v-bind:value="2" v-model="add.numbertype"
                            :checked="add.numbertype == 2" />
                        <label for="callback_number" class="p-mr-2">Callback Number</label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Reset" icon="pi pi-refresh" class="p-button-text" @click="reSetFilteredData()" />
            <Button label="Search" icon="pi pi-search" class="p-mr-2" autofocus
                @click="getFilteredData(client_fks,operator_fks,add.didnumber,state_fks,city_fks,add.numbertype)" />
        </template>
    </Dialog>
    <!----End of filter--------------------------------->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import headerComponent from '../common/commonHeaderOfDIDNumber.vue'
import { required, helpers, minLength, maxLength, numeric } from '@vuelidate/validators';
export default {
    components: { headerComponent },
    data() {
        return {
            v$: useValidate(),
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addDIDNumberDialogStatus: false,
            updateDIDNumberDialogStatus: false,
            filterListingDialog: false,
            row_id: null,
            didnumberlist: [],
            operatorList: [
                { value: "1", label: "Vodafone" },
                { value: "2", label: "Idea" },
                { value: "3", label: "Airtel" },
                { value: "4", label: "Not Known" },
                { value: "5", label: "BSNL" },
                { value: "6", label: "Jio" },
                { value: "7", label: "TATA" },
                { value: "8", label: "Reliance" },
            ],
            statesList: [],
            citiesList: [],
            showLoader: false,
            submitted: false,
            showFillFilter: false,
            statusList: [
                { value: "0", label: "Free" },
                { value: "1", label: "Active" },
                { value: "2", label: "Under client notice period" },
                { value: "3", label: "request submited to operator" },
                { value: "4", label: "Release Number" },
            ],
            add: {
                v$: useValidate(),
                didnumber: '',
                operator: '',
                state: '',
                city: '',
                numbertype: '',
                defaultnumberstatus: '',
            },
            edit: {
                v$: useValidate(),
                numbertype: '',
                defaultnumberstatus: '',
                status: '',
            },
            client: '',
            clientList: [],
            client_fks: [],
            operator_fks: [],
            state_fks: [],
            city_fks: [],
            showloaderpage: false,
            allRowData: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getDIDNumberByStatus({ page_no: this.page_no, cn16: 3 });
        this.getStates();
    },
    validations() {
        return {
            add: {
                didnumber: { required: helpers.withMessage('Please Enter DID Number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(10), maxLength: maxLength(12) },
                operator: { required: helpers.withMessage('Please Select Operator', required) },
                state: { required: helpers.withMessage('Please Select State', required) },
                city: { required: helpers.withMessage('Please Select City', required) },
                // defaultnumberstatus: { required: helpers.withMessage('Please Select Default Number Status', required) },
            },
            edit: {
                // defaultnumberstatus: { required: helpers.withMessage('Please Select Default Number Status', required) },
                status: { required: helpers.withMessage('Please Select Status', required) },
            }
        };
    },
    methods: {
        getDIDNumberByStatus(ev) {
            this.loading = true;
            this.ApiService.getDIDNumberByStatus(ev).then((data) => {
                if (data.status == 200) {
                    this.didnumberlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.didnumberlist = [];
                }
                this.loading = false;
            });
        },
        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getDIDNumberByStatus({ page_no: this.page_no, cn16: 3 });
        },
        addDIDNumberDialogOpen() {
            this.submitted = false;
            this.addDIDNumberDialogStatus = true;
        },
        editDIDNumberDialogOpen(e) {
            this.allRowData = e;
            this.submitted = false;
            this.updateDIDNumberDialogStatus = true;
            this.edit.defaultnumberstatus = e.cn24;
            this.edit.numbertype = e.cn13;
            this.row_id = e.cn1;

            let cn16 = this.statusList.filter(function (item) {
                return item.value == e.cn16;
            });
            if (cn16.length > 0) {
                this.status = cn16[0];
            }
        },
        reloadnewDIDnumberList() {
            this.loading = true;
            this.ApiService.getDIDNumberByStatus({ cn16: 3 }).then((data) => {
                if (data.status == 200) {
                    this.didnumberlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.didnumberlist = '';
                }
                this.loading = false;
            });
        },
        addNewDIDNumber() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['cn2'] = this.add.didnumber;
            fields['cn3'] = this.add.operator.value;
            fields['cn4'] = this.add.operator.label;
            fields['cn20'] = this.add.state.value;
            fields['cn21'] = this.add.city.value;
            fields['cn22'] = this.add.city.label;
            fields['cn13'] = this.add.numbertype;
            fields['cn24'] = this.add.defaultnumberstatus;

            this.v$.add.$validate();
            if (!this.v$.add.$error) {
                this.loading = true;
                this.ApiService.createDIDNumber(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.addDIDNumberDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.add.didnumber = '';
                        this.add.operator = '';
                        this.add.state = '';
                        this.add.city = '';
                        this.add.numbertype = 1;
                        this.add.defaultnumberstatus = 1;
                        this.reloadnewDIDnumberList();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        updateDIDNumber() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};

            fields['flag'] = 1;
            fields['cn1'] = this.row_id;
            fields['cn16'] = this.edit.status.value;
            fields['cn13'] = this.edit.numbertype;
            fields['cn24'] = this.edit.defaultnumberstatus;
            fields['cn2'] = this.allRowData.cn2;
            fields['cn14'] = this.allRowData.cn14;


            this.v$.edit.$validate();
            if (!this.v$.edit.$error) {
                this.loading = true;
                this.ApiService.updateDIDNumber(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        var successMsg = items.message;
                        this.updateDIDNumberDialogStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.reloadnewDIDnumberList();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        filterlisting() {
            this.filterListingDialog = true;
            this.getClients();
        },
        getFilteredData(client, operator, didnumber, state, city, numbertype) {
            this.loading = true;
            this.client_fks = client;
            this.operator_fks = operator;
            this.state_fks = state;
            this.didnumber = didnumber;
            this.city_fks = city;
            this.numbertype = numbertype;
            this.getDIDNumberByStatus({
                page_no: this.page_no,
                cn16: 3,
                cn14: this.client_fks,
                cn3: this.operator_fks,
                cn20: this.state_fks,
                cn2: this.didnumber,
                cn21: this.city_fks,
                cn13: this.numbertype,
            });
            this.showFillFilter = true;
            this.filterListingDialog = false;
        },
        reSetFilteredData() {
            this.client_fks = "";
            this.operator_fks = "";
            this.state_fks = "";
            this.didnumber = "";
            this.city_fks = "";
            this.numbertype = "";
            this.reloadnewDIDnumberList();
            this.showFillFilter = false;
            this.filterListingDialog = false;
        },
        getStates(ev) {
            this.ApiService.getStates(ev).then((items) => {
                if (items.success === true) {
                    this.statesList = items.data;
                } else {
                    this.statesList = [];
                }
            });
        },
        selectclientcity(ev) {
            this.getCity(ev);
        },
        getCity(e) {
            if (e.value) {
                this.ApiService.getcities({ state_id: e.value }).then((items) => {
                    if (items.status === 200) {
                        this.citiesList = items.data;
                    } else {
                        this.citiesList = [];
                    }
                });
            }
        },
        getClients(ev) {
            this.ApiService.getClientListOnly(ev).then((items) => {
                if (items.success === true) {
                    this.clientList = items.data;
                } else {
                    this.clientList = '';
                }
            });
        }

    },
};
</script>

<style scoped lang="scss">
.user-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-active {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-per-suspend {
        background: #ffcdd2;
        color: #c63737;
    }

    &.status-temp-suspend {
        background: #ffd8b2;
        color: #805b36;
    }
}
</style>

<style lang="scss" scoped>
/* Responsive */
.p-datatable-customers .p-datatable-tbody>tr>td .p-column-title {
    display: none;
}

.p-button-xs {
    width: 1rem;
}

.p-button.p-button-xs {
    padding: 0.925rem 0.925rem;
}

.p-button.p-button-xs .p-button-icon {
    font-size: 0.675rem;
}

.p-button.p-button-xs.p-button-icon-only.p-button-rounded {
    height: 1rem;
}

::v-deep(.p-progressbar) {
    height: 1rem;
    background-color: #d8dadc;

    .p-progressbar-value {
        background-color: #689f38;
    }

    .p-progressbar-label {
        display: block;
        color: #ffffff;
        line-height: 13px;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {

            .p-datatable-thead>tr>th,
            .p-datatable-tfoot>tr>td {
                display: none !important;
            }

            .p-datatable-tbody>tr {
                border-bottom: 1px solid #dee2e6;

                >td {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0 none !important;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none;

                    .p-column-title {
                        padding: 0.4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -0.4rem 1rem -0.4rem -0.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: 0.5rem;
                    }
                }
            }
        }
    }
}

.dashboard-card4 {
    box-shadow: 0 3px 1px -1px rgba(0, 0, 0, .2), 0 10px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    cursor: progress;
    color: #1d37cc;
}

.dashboard-card:hover {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 10px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
    cursor: pointer;
    color: #1d37cc;
}
</style>